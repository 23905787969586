
import React from 'react';
import { validateUrl } from '../utility/validate';
function Contact() {
    return (
        <div>
            <h1>Contact</h1>
        </div>
    );
}

export default validateUrl(Contact);